import "@/src/styles/index.scss";
import { useRouter } from "next/router";
import { useEffect } from "react";
import * as gtag from "gtag";

import { Poppins, DM_Sans, Raleway } from "next/font/google";

const poppins = Poppins({
    preload: false,
    style: ["normal", "italic"],
    subsets: ["latin"],
    weight: ["300", "400", "500", "600", "700", "800"],
    display: "swap",
    variable: "--tp-ff-poppins",
});
const dmSans = DM_Sans({
    preload: false,
    subsets: ["latin"],
    weight: ["400", "500", "700"],
    display: "swap",
    variable: "--tp-ff-dm-sans",
});
const raleway = Raleway({
    preload: false,
    subsets: ["latin"],
    weight: ["300", "400", "500", "600", "700", "800", "900"],
    display: "swap",
    variable: "--tp-ff-raleway",
});

// @ts-ignore
export default function App({ Component, pageProps }) {
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url: any) => {
            gtag.pageview(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <div
            className={`body ${poppins.variable} ${dmSans.variable} ${raleway.variable}`}
        >
            <Component {...pageProps} />
        </div>
    );
}
